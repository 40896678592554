import React from "react";
import { Trans } from "react-i18next";

import { getDurationPerDay } from "@/util/helpers.mjs";
import { getLocaleString } from "@/util/i18n-helper.mjs";

function DurationPerDay({ milliseconds, totalMillis }) {
  const { hours, minutes, seconds } = getDurationPerDay(
    milliseconds,
    totalMillis,
  );
  let i18nKey = "";
  let durationText = "";
  let duration = 0;

  if (hours > 0) {
    i18nKey =
      hours === 1
        ? "common:yearReview.hourPerDay"
        : "common:yearInReview.hoursPerDay";
    durationText = hours === 1 ? "hour" : "hours";
    duration = hours;
  } else if (minutes > 0) {
    i18nKey =
      minutes === 1
        ? "common:yearReview.minutePerDay"
        : "common:yearInReview.minutesPerDay";
    durationText = minutes === 1 ? "minute" : "minutes";
    duration = minutes;
  } else {
    i18nKey =
      seconds === 1
        ? "common:yearReview.secondPerDay"
        : "common:yearInReview.secondsPerDay";
    durationText = seconds === 1 ? "second" : "seconds";
    duration = seconds;
  }

  return (
    <Trans i18nKey={i18nKey}>
      That&apos;s equivalent to{" "}
      <span className="type-title--bold">
        {getLocaleString(duration, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        })}{" "}
        {durationText} per day.
      </span>
    </Trans>
  );
}

export default DurationPerDay;
