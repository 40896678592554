import React from "react";

import ReviewBackground from "@/inline-assets/val-review-background.svg";

function SeasonAgentBackground({ backgroundColor }) {
  return (
    <ReviewBackground
      style={{ color: backgroundColor }}
      className="original-colors background"
    />
  );
}

export default SeasonAgentBackground;
