import { css, styled } from "goober";

export const Container = styled("div")`
  width: 100%;
  margin: 0 auto;
`;

export const HeaderCSS = () => css`
  height: 70dvh;
  .header-image {
    width: 360px;
    height: 360px;
    img {
      width: 100%;
      height: auto;
      transform: scale(2) translateY(8%);
    }
  }
  &:has(div.cannot-record),
  &:has(.loading) {
    height: 80dvh;
  }
  .cannot-record {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      margin: var(--sp-2) 0;
      width: fit-content;
    }
  }
`;

export const DamageDealtCSS = () => css`
  .callout-bars {
    justify-content: space-around !important;
  }

  .callout-icon {
    svg {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }
  }

  .callout-bar--image {
    &::after {
      box-shadow: initial !important;
    }
    width: var(--bar-width);
    height: var(--bar-width);
    img {
      width: calc(var(--sp-5) + var(--bar-width)) !important;
      height: auto;
      transform: translate(-50%, -50%) rotate(25deg);
      margin-left: 50%;
      margin-top: 50%;
    }
  }
`;

export const KDACSS = () => css`
  .callout-icon {
    svg {
      width: auto !important;
      height: 2.5rem !important;
    }
  }

  .callout-bars {
    justify-content: space-around !important;
  }
`;

export const MAPIconCSS = () => css`
  object-fit: cover;
  width: var(--sp-16);
  height: var(--sp-16);
  border-radius: var(--br);
`;

export const AgentBackgroundCSS = () => css`
  .background-image {
    -webkit-mask-image: initial !important;
    svg {
      width: 100%;
      height: 100%;
      -webkit-mask-image: radial-gradient(
        ellipse at top,
        hsl(0deg 0% 0% / 30%),
        transparent 70%
      );
    }
    img {
      position: absolute;
      right: 0;
      top: 0;
      width: calc(20 * var(--sp-10)) !important;
      height: auto !important;
      @container season-section (width <= 900px) {
        transform: translateX(20%);
        -webkit-mask-image: radial-gradient(
          ellipse at top,
          hsl(0deg 0% 0% / 30%),
          transparent 70%
        );
      }
    }
  }
`;

export const WeaponBackgroundCSS = () => css`
  .background-image {
    -webkit-mask-image: initial !important;
    svg {
      width: 100%;
      height: 100%;
      -webkit-mask-image: radial-gradient(
        ellipse at top,
        hsl(0deg 0% 0% / 30%),
        transparent 70%
      );
    }
    img {
      position: absolute;
      right: calc(var(--sp-10) * -2.5);
      top: calc(var(--sp-10) * 2.5);
      width: calc(20 * var(--sp-10)) !important;
      height: auto !important;
      @container (width <= 700px) {
        -webkit-mask-image: radial-gradient(
          ellipse at top,
          hsl(0deg 0% 0% / 30%),
          transparent 70%
        );
      }
    }
  }
`;

export const HeadshotCSS = () => css`
  .callout-image {
    height: var(--sp-14);
    width: var(--sp-14);
    img {
      height: calc(var(--sp-10) * 2.5);
      width: calc(var(--sp-10) * 2.5);
      transform: translate(-20%);
    }
  }
`;

export const FirstBloodCSS = () => css`
  width: calc(10 * var(--sp-10));
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -50%);
  @container season-section (width <= 700px) {
    -webkit-mask-image: radial-gradient(
      ellipse at top,
      hsl(0deg 0% 0% / 30%),
      transparent 70%
    );
  }
`;

export const AgentWinRateCSS = () => css`
  @container season-section (inline-size <= 600px) {
    .callout-bar--value {
      font-size: var(--sp-2_5e) !important;
    }
  }

  .callout-bar--value {
    font-size: var(--sp-3e) !important;
  }

  .callout-bars {
    justify-content: space-around !important;
  }

  .callout-icon {
    svg {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }
  }
`;

export const HoursPlayedCSS = () => css`
  .callout-bars {
    justify-content: space-around !important;
  }

  .callout-icon {
    svg {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }
  }
`;
