import React from "react";
import { styled } from "goober";

import { appURLs } from "@/app/app-urls.mjs";

const Container = styled("div")`
  position: absolute;
  inset: 0 0 0 0;
  top: calc(-4 * var(--sp-5));
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  @font-face {
    font-family: "DrukCondSuper";
    src: url("${appURLs.CDN_PLAIN}/blitz/ui/fonts/DrukCond-Super.woff2")
      format("woff2");
  }

  p {
    font-family: "DrukCondSuper";
    font-size: 20rem;
    margin: 0;
    line-height: 0.8;
    color: var(--red);
    text-transform: uppercase;
  }
`;

function BackgroundName({ names = ["Season", "Review"] }) {
  return (
    <Container>
      {names.map((name, index) => (
        <p key={index}>{name}</p>
      ))}
      {names.map((name, index) => (
        <p key={index}>{name}</p>
      ))}
      {names.map((name, index) => (
        <p key={index}>{name}</p>
      ))}
    </Container>
  );
}

export default BackgroundName;
